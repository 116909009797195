<template>
  <div
    :class="`w-full h-[71px]  mt-[20px] ${
      idSelect === peerId || idHover === peerId ? 'bg-ems-gray600' : ''
    } flex items-center px-[16px] space-x-[23px] rounded-lg relative cursor-pointer rounded-2xl`"
    @mouseenter="idHover = peerId"
    @mouseleave="idHover = ''"
    @click="handleClick(entry)"
  >
    <div
      :class="`rounded-full w-[48px] h-[48px] ${
        dialogType === 1 ? 'bg-white' : 'bg-ems-boTro2_500'
      } relative`"
    >
      <div
        :class="`rounded-full w-[48px] h-[48px] flex justify-center items-center overflow-hidden flex-shrink-0 ${
          idSelect === peerId && onCall ? 'border-3 border-ems-main4' : ''
        }`"
      >
        <img
          v-if="dialogType === 1"
          alt="avatar"
          :src="avt"
          class="w-ful h-auto"
        />
        <div v-else>
          <p class="font-semibold text-[14px] uppercase text-white">
            {{ peerName && peerName.charAt(0) }}
          </p>
        </div>
      </div>
      <div
        v-if="onCall"
        class="h-[20px] w-[20px] bg-ems-gray700 absolute rounded-full top-0 right-[-5px] flex items-center justify-center"
      >
        <div
          class="flex items-center justify-center h-[15px] w-[15px] rounded-full bg-ems-main4"
        >
          <img src="@/static/img/icon/volumn_avt.svg" />
        </div>
      </div>
    </div>
    <div class="space-y-[8px] w-full">
      <p class="text-white font-semibold text-[16px]">
        {{ peerName }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import VueTypes from 'vue-types';
const emit = defineEmits(['handleSelectItemUser']);
const props = defineProps({
  onCall: VueTypes.bool.def(false),
  peerId: VueTypes.string.def(''),
  peerName: VueTypes.string.def(''),
  dialogType: VueTypes.number.def(0),
  avt: VueTypes.string.def(''),
  idSelect: VueTypes.bool.def(false),
  idHover: VueTypes.bool.def(false),
});
const handleClick = (value) => {
  emit('handleSelectItemUser', value);
};
</script>
<style lang="scss">

</style>
