<template>
  <div :class="`custom-tab  bg-ems-gray800 h-full px-[16px] overflow-hidden`">
    <div class="w-full mt-[10px] h-[92vh] pb-5">
      <InputSearch v-model="searchQuery" :placeholder="t('common.search')" />
      <div
        ref="scrollContainer"
        class="hidden-scroll overflow-x-hidden"
        :style="{ height: `${windowHeight - 280}px` }"
        @scroll="handleScroll"
      >
        <div v-for="(entry, entryIndex) in filterContacts" :key="entryIndex">
          <ItemUser
            :peerId="entry.userId || ''"
            :onCall="false"
            :avt="entry.avatar || ''"
            :id-hover="hoveredItem"
            :id-select="idSelect"
            :peerName="entry.fullName"
            :dialogType="1"
            @handle-select-item-user="handleClick(entry)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useWindowHeight } from '@/util/common-utils';
import { TAB_COORDINATION } from '@/util/common-constant.js';
import { useI18n } from 'vue-i18n';
import ItemUser from './item-user.vue';
import InputSearch from '../input-search.vue';
const { t } = useI18n();
const { state, dispatch } = useStore();
const listContactsInit = computed(() => state.coordinator.listContactsInit);
const { windowHeight } = useWindowHeight();
const idSelect = ref('');
const searchQuery = ref('');
const scrollContainer = ref(null);
const hoveredItem = ref('');
onMounted(async () => {
  dispatch('coordinator/getListContacts').then(() => {
    handleClick(listContactsInit.value[0]);
  });
});
const filterContacts = computed(() => {
  return listContactsInit.value.filter((user) =>
    user.fullName.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});
const handleSendParams = (item) => {
  let query = {
    id: item.userId,
    tab:TAB_COORDINATION.CONTACT
  };
  dispatch('coordinator/handleSetParams', query);
};
const handleClick = (item) => {
  handleSendParams(item);
  idSelect.value = item.userId;
  let value = {
    otherInfo: {
      peerAvatarUrl: item.avatar,
      peerId: item.userId,
      peerName: item.fullName,
      peerType: 1,
      status: item.userStatus.online,
      like: item.like,
    },
    participants:[],
    tab: TAB_COORDINATION.CONTACT,
  };
  dispatch('coordinator/selectValue', value);
  dispatch('coordinator/getListStatus', item.userId);
};
</script>
<style>

</style>
